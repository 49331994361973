<template>
  <div class="box">
    <!-- <h1>自动播放内容，播放结束后跳转到分类单点页面</h1> -->
    <div class="content">
      <div class="img_3d">
        <img v-if="fileType == 'V'" :src="envBox" alt="" />
        <img v-else :src="box3dGif" alt="" />
        <!-- <div class="swiper-box">
          <div class="wrapper">
            <swiper ref="mySwiper" v-bind:options="swiperOptions">
              <swiper-slide
                v-for="(item, index) in img3dList"
                v-bind:key="index"
              >
                <div class="bac">
                  <img :src="item" alt="" />
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div> -->
        <!-- <video src="@/assets/video/xg.mp4" loop controls></video> -->
      </div>
      <div class="item_box">
        <div class="item1 user_info" v-if="activeIndex == 0">
          <dv-border-box-11 title="基本信息">
            <div class="user">
              <ul class="item_li" v-if="fileType == 'E'">
                <li>
                  <span class="title">业务类型</span
                  ><span class="date">物证委托</span>
                </li>
                <li class="border_bottom">
                  <span class="title">物证保全编号</span
                  ><span class="date">1694769042000</span>
                </li>
              </ul>
              <ul class="item_li" v-if="fileType == 'U'">
                <li>
                  <span class="title">业务类型</span
                  ><span class="date">银行核保</span>
                </li>
                <li class="border_bottom">
                  <span class="title">物证保全编号</span
                  ><span class="date">{{ underwrite.finishTime }}</span>
                </li>
              </ul>
              <!-- 环保 -->
              <ul class="item_li" v-if="fileType == 'V'">
                <li>
                  <span class="title">类型</span
                  ><span class="date">环保移动监测</span>
                </li>
                <li>
                  <span class="title">环保保全编号</span
                  ><span class="date">202310230154</span>
                </li>
              </ul>
              <vue-seamless-scroll
                :data="listData"
                :class-option="optionSingleHeight"
                class="seamless-warp"
                @ScrollEnd="handleScrollEnd"
              >
                <ul class="item_li">
                  <li v-for="(item, index) in listData" :key="index">
                    <span class="title" v-text="item.name"></span
                    ><span class="date" v-text="item.value"></span>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </dv-border-box-11>
        </div>
        <div class="item1 track step" v-if="activeIndex == 1">
          <template v-if="fileType == 'E'">
            <transition mode="out-in" :duration="1000">
              <div class="track_time animated bounceIn" v-if="trackNormal">
                <img :src="normal1" />
              </div>
            </transition>
            <!--   <div class="track_info">
              物证轨迹开始时间：2023/07/04 13:38:10 物证轨迹结束时间：2023/07/04
              14:20:10 物证轨迹开始地点：厦门市湖里区湖里大道35号
              物证轨迹结束地点：厦门市集美区鳌园路华侨大学西南门
            </div> -->
            <dv-border-box-11 title="物证轨迹">
              <baidu-map
                class="map"
                :center="center"
                :zoom="zoom"
                :scroll-wheel-zoom="true"
                @ready="handler"
              >
                <!--   <bm-driving
                  :start="startMark"
                  :end="endMark"
                  @searchcomplete="handleSearchComplete"
                  :panel="false"
                  :autoViewport="true"
                ></bm-driving> -->
                <!-- <bml-lushu
                  :path="ePath"
                  :icon="icon"
                  :play="play"
                  :rotation="true"
                  :speed="3000"
                >
                </bml-lushu> -->
                <!-- 添加终点标记 -->
                <bm-marker
                  :position="endPoint"
                  :icon="{
                    url: endPointImg,
                    size: { width: 50, height: 50 },
                  }"
                ></bm-marker>

                <!-- 添加起点标记 -->
                <bm-marker
                  :position="ePath[0]"
                  :icon="{
                    url: startPointImg,
                    size: { width: 50, height: 50 },
                  }"
                ></bm-marker>
                <bm-polyline
                  :path="ePath"
                  stroke-color="#4aa4f3"
                  :stroke-opacity="1"
                  :stroke-weight="6"
                  stroke-style="dashed"
                />
                <bml-lushu
                  :path="ePath"
                  :icon="icon"
                  :play="play"
                  :rotation="true"
                  :speed="controlSpeed"
                >
                </bml-lushu>
              </baidu-map>
            </dv-border-box-11>
          </template>
          <template v-if="fileType == 'U'">
            <dv-border-box-11 title="过程解析" class="flex">
              <div class="swiper-box_step">
                <div class="wrapper_step">
                  <el-carousel
                    :interval="3000"
                    arrow="hover"
                    indicator-position="none"
                  >
                    <el-carousel-item
                      v-for="(item, index) in stepImg"
                      :key="index"
                    >
                      <img :src="item.url" alt="" />
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </dv-border-box-11>
          </template>
          <template v-else-if="fileType == 'V'">
            <dv-border-box-11 title="过程解析" class="flex">
              <div class="swiper-box_step">
                <div class="wrapper_step">
                  <!--   <swiper
                    ref="stepSwiper"
                    class="swiper_step"
                    :options="swiperOptionsSteps"
                  >
                    <swiper-slide v-for="(item, index) in stepImg" :key="index">
                      <img :src="item.url" alt="" />
                    </swiper-slide>
                  </swiper> -->
                  <el-carousel
                    :interval="5000"
                    arrow="hover"
                    indicator-position="none"
                  >
                    <el-carousel-item
                      v-for="(item, index) in stepImgEnv"
                      :key="index"
                    >
                      <img :src="item.url" alt="" />
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </dv-border-box-11>
          </template>
        </div>
        <div class="item1 image" v-if="activeIndex == 2">
          <template v-if="fileType == 'E'">
            <dv-border-box-11 title="关键场景">
              <div class="swiper-box">
                <div class="wrapper">
                  <swiper ref="mySwiper" v-bind:options="swiperOptions">
                    <swiper-slide
                      v-for="(item, index) in imgList"
                      v-bind:key="index"
                    >
                      <!-- <div
                        class="bac"
                        :style="{ background: ' url(' + item.url + ')' }"
                      ></div>
                      <div class="img_info">
                        <p class="title">{{ item.title }}</p>
                        <p>时间:{{ item.time }}</p>
                        <p>大小:{{ item.size }}</p>
                        <p>分辨率:{{ item.cn }}</p>
                        <p>水平分辨率:{{ item.level }}</p>
                        <p>垂直分辨率:{{ item.vertical }}</p>
                        <p>位深度:{{ item.bits }}</p>
                        <p>是否压缩:{{ item.compress }}</p>
                        <p>箱盖状态:{{ item.isLock }}</p>
                        <p>温度:{{ item.temperature }}</p>
                        <p>大气压:{{ item.pressure }}</p>
                        <p>电池电量:{{ item.batteryPercentage }}</p>
                        <p class="notice">
                          <img :src="normal1" alt="" srcset="" />
                        </p>
                      </div> -->
                      <div
                        class="bac"
                        :style="{ background: 'url(' + item.url + ')' }"
                      >
                        <img
                          :src="item.url"
                          alt=""
                          srcset=""
                          class="swiper_img"
                        />
                        <!-- {{ item.url }} -->
                      </div>
                      <div class="img_info">
                        <!-- <p class="title">{{ item.title }}</p> -->
                        <!-- <p>时间:{{ item.time }}</p> -->
                        <p>大小:{{ item.size }}</p>
                        <p>分辨率:{{ item.cn }}</p>
                        <p>水平分辨率:{{ item.level }}</p>
                        <p>垂直分辨率:{{ item.vertical }}</p>
                        <p>位深度:{{ item.bits }}</p>
                        <p>是否压缩:{{ item.compress }}</p>
                        <p>箱盖状态:{{ item.isLock }}</p>
                        <p>温度:{{ item.temperature }}</p>
                        <p>大气压:{{ item.pressure }}</p>
                        <p>电池电量:{{ item.batteryPercentage }}</p>
                        <div class="notice">
                          <img :src="normal1" alt="" srcset="" />
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </dv-border-box-11>
          </template>
          <template v-if="fileType == 'U'">
            <dv-border-box-11 title="关键场景">
              <div class="sweiper_key">
                <el-carousel
                  :interval="5000"
                  arrow="always"
                  indicator-position="none"
                >
                  <el-carousel-item
                    v-for="(item, index) in keyStepImg"
                    :key="index"
                  >
                    <div class="title">
                      <img :src="item.url" alt="" />
                    </div>
                    <div class="info">
                      <template v-if="index == 0">
                        <div class="info_item">
                          <span> 核保发起地址：</span>
                          <span> {{ underwrite.address }}</span>
                        </div>
                        <div class="info_item">
                          <span> 核保人：</span>
                          <span> {{ underwrite.idName }}</span>
                        </div>
                        <div class="info_item">
                          <span> 核保人身份证号：</span>
                          <span> {{ underwrite.idNo }}</span>
                        </div>
                        <div class="info_item">
                          <span> 核保人身份证头像：</span>
                          <span>
                            <el-image
                              :src="underwrite.idImg"
                              :preview-src-list="[underwrite.idImg]"
                              style="width: 120px; height: 120px"
                            >
                            </el-image>
                          </span>
                        </div>
                        <div class="info_item info_img">
                          <span> 指纹：</span>
                          <span>
                            <el-image
                              :src="underwrite.fingerPrint"
                              :preview-src-list="[underwrite.fingerPrint]"
                              style="width: 120px; height: 120px"
                            >
                            </el-image>
                          </span>
                        </div>
                        <div class="info_item info_img">
                          <span> 人脸扫描：</span>
                          <span>
                            <el-image
                              :src="underwrite.scanFace"
                              :preview-src-list="[underwrite.scanFace]"
                              style="width: 120px; height: 120px"
                            >
                            </el-image>
                          </span>
                        </div>
                      </template>
                      <template v-if="index == 1">
                        <div class="info_item">
                          <span> 文件确认签名：</span>
                          <span>
                            <el-image
                              :src="underwrite.fileSign"
                              :preview-src-list="[underwrite.fileSign]"
                              style="width: 200px; height: 200px"
                            >
                            </el-image>
                          </span>
                        </div>
                        <div class="info_item info_img">
                          <span> 扫描文件签名：</span>
                          <span>
                            <el-image
                              :src="underwrite.scanSign"
                              :preview-src-list="[underwrite.scanSign]"
                              style="width: 200px; height: 200px"
                            >
                            </el-image>
                          </span>
                        </div>
                      </template>
                      <template v-if="index == 2">
                        <div class="info_item info_img">
                          <span> 核保扫描文件：</span>
                          <span>
                            <el-image
                              :src="underwrite.scanFile"
                              :preview-src-list="[underwrite.scanFile]"
                              style="width: 120px; height: 120px"
                            >
                            </el-image>
                          </span>
                        </div>
                        <div class="info_item info_img">
                          <span> 核保过程音频：</span>
                          <span>
                            <audio :src="underwrite.audio" controls></audio>
                          </span>
                        </div>
                        <div class="info_item info_img">
                          <span> 核保过程视频：</span>
                          <span class="key_video">
                            <EasyPlayer
                              :video-url="underwrite.mp4"
                              :live="false"
                              :autoplay="true"
                              :loop="false"
                              stretch="true"
                              ref="easyPlayer"
                              alt="暂无视频数据"
                            ></EasyPlayer>
                            <!-- <video
                              :src="underwrite.mp4"
                              class="video"
                              controls
                            ></video> -->
                          </span>
                        </div>
                      </template>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </dv-border-box-11>
          </template>
          <template v-if="fileType == 'V'">
            <dv-border-box-11 title="关键场景">
              <div class="sweiper_key">
                <el-carousel
                  :interval="5000"
                  arrow="always"
                  :autoplay="true"
                  :hover-stop="true"
                  indicator-position="none"
                >
                  <el-carousel-item
                    v-for="(item, index) in keyStepImg"
                    :key="index"
                  >
                    <div
                      :class="{
                        info: true,
                        sample_info: true,
                      }"
                    >
                      <template v-if="index == 0">
                        <div class="info_item">
                          <span> 企业名称：</span>
                          <span v-if="workTask.inspectAddress">
                            {{ workTask.inspectAddress }}</span
                          >
                          <span v-else>暂无数据</span>
                        </div>
                        <!--   <div class="info_item">
                          <span> 企业电话：</span>
                          <span> {{ envDetail.inspectPhone }}</span>
                        </div> -->
                        <div class="info_item">
                          <span> 工作地点：</span>
                          <span v-if="workTask.inspectName">
                            {{ workTask.inspectName }}</span
                          >
                          <span v-else>暂无数据</span>
                        </div>
                        <div class="info_item">
                          <span>工作证照：</span>
                          <span v-if="workTask.sceneCard">
                            <span
                              v-for="(img, i) in workTask.sceneCard"
                              :key="i"
                            >
                              <el-image
                                :src="img"
                                :preview-src-list="workTask.sceneCard"
                                style="width: 100px; height: 100px"
                              >
                              </el-image>
                            </span>
                          </span>
                          <span v-else>暂无数据</span>
                        </div>
                        <div class="info_item">
                          <span> 代表人身份证号：</span>
                          <span v-if="workTask.sceneIdentity">
                            {{ workTask.sceneIdentity }}</span
                          >
                          <span v-else>暂无数据</span>
                        </div>

                        <div class="info_item">
                          <span> 代表人姓名：</span>
                          <span v-if="workTask.sceneUsername">
                            {{ workTask.sceneUsername }}</span
                          >
                          <span v-else>暂无数据</span>
                        </div>
                        <div class="info_item">
                          <span> 代表人签名：</span>
                          <span v-if="workTask.sceneSign">
                            <el-image
                              :src="workTask.sceneSign"
                              :preview-src-list="[workTask.sceneSign]"
                              style="width: 100px; height: 100px"
                            >
                            </el-image>
                          </span>
                          <span v-else></span>
                        </div>
                        <!--     <div class="info_item">
                          <span> 代表人工作证：</span>
                          <span
                            v-for="(img, i) in workTask.sceneCard"
                            :key="i"
                          >
                            <el-image
                              :src="img"
                              :preview-src-list="workTask.sceneCard"
                              style="width: 100px; height: 100px"
                            >
                            </el-image>
                          </span>
                        </div> -->
                      </template>
                      <template v-if="index == 1">
                        <div class="sample">
                          <div class="sample_left sample_item">
                            <div class="info_item">
                              <span> 气象条件：</span>
                              <span v-if="workTask.weather">
                                {{ workTask.weather }}</span
                              >
                              <span v-else></span>
                            </div>
                            <div class="info_item">
                              <span> 生产工况：</span>
                              <span v-if="workTask.produceState">
                                {{ workTask.produceState }}</span
                              >
                              <span v-else>暂无数据</span>
                            </div>
                            <div class="info_item">
                              <span> 处理工艺：</span>
                              <span v-if="workTask.processName">
                                {{ workTask.processName }}</span
                              >
                              <span v-else>暂无数据</span>
                            </div>
                            <div class="info_item">
                              <span>排污口是否规范 ：</span>
                              <span v-if="workTask.isStandard">
                                {{ workTask.isStandard ? "是" : "否" }}</span
                              >
                              <span v-else>暂无数据</span>
                            </div>
                            <!--  <div class="info_item">
                              <span> 是否生产废水：</span>
                              <span v-if="workTask.waterProduct">
                                {{ workTask.waterProduct ? "是" : "否" }}</span
                              >
                              <span v-else></span>
                            </div>
                            <div class="info_item">
                              <span> 是否生活废水：</span>
                              <span v-if="workTask.waterLive">
                                {{ workTask.waterLive ? "是" : "否" }}</span
                              >
                              <span v-else></span>
                            </div> -->
                            <div class="info_item info_img">
                              <span> 采样照片：</span>
                              <span v-if="workTask.sampleImg.length">
                                <span
                                  v-for="(img, i) in workTask.sampleImg"
                                  :key="i"
                                >
                                  <el-image
                                    :src="img"
                                    :preview-src-list="workTask.sampleImg"
                                    style="width: 100px; height: 100px"
                                  >
                                  </el-image>
                                </span>
                              </span>
                              <span v-else>暂无数据</span>
                            </div>
                            <div class="info_item info_img">
                              <span> 报告单：</span>
                              <!-- <span
                            @click="openPdf"
                            v-for="i in numPages"
                            :key="i"
                            style="width: 100px; height: 150px"
                          >
                            <pdf ref="pdf" :src="pdfUrl" :page="i"></pdf>
                          </span> -->
                              <span v-if="workTask.recordPDF">
                                <span
                                  @click="openPdf()"
                                  style="
                                    display: inline-block;
                                    width: 100px;
                                    height: 150px;
                                  "
                                >
                                  <pdf
                                    ref="pdf"
                                    :src="workTask.recordPDF"
                                  ></pdf> </span
                              ></span>
                              <span v-else>暂无数据</span>
                            </div>
                          </div>
                          <div class="sample_right sample_item">
                            <div class="info_item info_img">
                              <span> 样品采集：</span>
                              <span v-if="workTask.sampleVideo.length != 0">
                                <span
                                  class="sample_video"
                                  v-for="(v, i) in workTask.dealVideo"
                                  :key="i"
                                >
                                  <EasyPlayer
                                    :video-url="v"
                                    :live="false"
                                    :autoplay="true"
                                    :loop="false"
                                    stretch="false"
                                    ref="easyPlayer"
                                    alt="暂无视频数据"
                                  ></EasyPlayer>
                                </span>

                                <!--   <video
                                  :src="envDetail.sampleVideo"
                                  class="video"
                                  controls
                                ></video> -->
                              </span>
                              <span v-else>暂无数据</span>
                            </div>
                            <div class="info_item info_img">
                              <span> 样品处理：</span>

                              <span v-if="workTask.dealVideo.length != 0">
                                <span
                                  class="sample_video"
                                  v-for="(v, i) in workTask.dealVideo"
                                  :key="i"
                                >
                                  <EasyPlayer
                                    :video-url="v"
                                    :live="false"
                                    :autoplay="true"
                                    :loop="false"
                                    stretch="false"
                                    ref="easyPlayer"
                                    alt="暂无视频数据"
                                  ></EasyPlayer
                                ></span>

                                <!-- <video
                                  :src="envDetail.dealVideo"
                                  class="video"
                                  controls
                                ></video> -->
                              </span>
                              <span v-else>暂无数据</span>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-if="index == 2">
                        <div class="info_item info_img">
                          <span> 运输照片：</span>
                          <span v-if="workTask.transportImg.length != 0"
                            ><span
                              v-for="(img, i) in workTask.transportImg"
                              :key="i"
                            >
                              <el-image
                                :src="img"
                                style="width: 100px; height: 100px"
                              >
                              </el-image> </span
                          ></span>
                          <span v-else>暂无数据</span>
                        </div>

                        <div class="info_item info_img">
                          <span> 运输轨迹：</span>
                          <span v-if="ePath.length != 0">
                            <baidu-map
                              class="sample_map"
                              :center="center"
                              :zoom="zoom"
                              :scroll-wheel-zoom="true"
                              @ready="handler"
                            >
                              <!-- 添加终点标记 -->
                              <bm-marker
                                :position="endPoint"
                                :icon="{
                                  url: endPointImg,
                                  size: { width: 50, height: 50 },
                                }"
                              ></bm-marker>

                              <!-- 添加起点标记 -->
                              <bm-marker
                                :position="ePath[0]"
                                :icon="{
                                  url: startPointImg,
                                  size: { width: 50, height: 50 },
                                }"
                              ></bm-marker>
                              <bm-polyline
                                :path="ePath"
                                stroke-color="#4aa4f3"
                                :stroke-opacity="1"
                                :stroke-weight="6"
                                stroke-style="dashed"
                              />
                              <bml-lushu
                                :path="ePath"
                                :icon="icon"
                                :play="play"
                                :rotation="true"
                                :speed="1000"
                              >
                              </bml-lushu>
                            </baidu-map>
                          </span>
                          <span v-else>暂无数据</span>
                        </div>
                      </template>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </dv-border-box-11>
          </template>
        </div>
        <div class="item1 evaluate" v-if="activeIndex == 3">
          <div class="rate">
            <v-chart :option="option_column" style="height: 800px"></v-chart>
          </div>
          <transition mode="out-in" :duration="1000">
            <div class="result animated bounceIn" v-if="isResult">
              <img :src="normal2" />
            </div>
          </transition>
        </div>
        <!-- <div class="btn left" @click="prevPage"></div>
        <div class="btn right" @click="nextPage"></div>
        <div class="process">
          <el-progress
            :show-text="false"
            :text-inside="true"
            :stroke-width="6"
            :percentage="progre"
            text-color="#ffffff"
          ></el-progress>
        </div> -->
      </div>

      <!--     <div class="item user_info" v-if="activeIndex == 0">
        <dv-border-box-11 title="信息">
          <div class="user">
            <vue-seamless-scroll
              :data="listData"
              :class-option="optionSingleHeight"
              class="seamless-warp"
            >
              <ul class="item_li">
                <li v-for="(item, index) in listData" :key="index">
                  <span class="title" v-text="item.name"></span
                  ><span class="date" v-text="item.value"></span>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </dv-border-box-11>
      </div> -->
      <!--    <div class="item track" v-if="activeIndex == 1">
        <dv-border-box-11 title="物证轨迹">
          <baidu-map
            class="map"
            :center="{ lng: 116.404, lat: 39.915 }"
            :zoom="5"
          >
            <bm-driving
              :start="startMark"
              :end="endMark"
              @searchcomplete="handleSearchComplete"
              :panel="false"
              :autoViewport="true"
            ></bm-driving>
            <bml-lushu
              @stop="reset"
              :path="path"
              :icon="icon"
              :play="play"
              :rotation="true"
              :speed="100"
            >
            </bml-lushu>
          </baidu-map>
        </dv-border-box-11>
      </div> -->
      <!--  <div class="item video" v-if="activeIndex == 2">
        <dv-border-box-11 title="视频">
          <video
            src="https://www.w3schools.com/html/movie.mp4"
            controls
            :autoplay="true"
            :muted="true"
            :loop="true"
            :playsinline="true"
            :webkit-playsinline="true"
          ></video>
        </dv-border-box-11>
      </div> -->
      <!--   <div class="item image" v-if="activeIndex == 2">
        <dv-border-box-11 title="照片">
          <div class="swiper-box">
            <div class="wrapper">
              <swiper ref="mySwiper" v-bind:options="swiperOptions">
                <swiper-slide
                  v-for="(item, index) in imgList"
                  v-bind:key="index"
                >
                  <div
                    class="bac"
                    :style="{ background: ' url(' + item + ')' }"
                  ></div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </dv-border-box-11>
      </div> -->
      <!--  <div class="under">
        <div class="process">
          <el-progress
            :text-inside="true"
            :stroke-width="16"
            :percentage="progre"
            text-color="#ffffff"
          ></el-progress>
        </div>
      </div> -->
      <!--  <div class="op">
        <div class="btn left" @click="prevPage"></div>
        <div class="btn right" @click="nextPage"></div>
      </div> -->
      <el-dialog :visible.sync="pdfVisible" width="30%" :modal="false">
        <div class="pdf">
          <pdf ref="pdf" :src="pdfUrl" :page="pageNum"></pdf>
        </div>
        <div style="margintop: 10px; color: #409eff">
          {{ pageNum }} / {{ numPages }}
        </div>
        <el-button type="primary" plain size="mini" @click="prePage"
          >上一页</el-button
        >
        <el-button type="primary" plain size="mini" @click="nextPage"
          >下一页</el-button
        >
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import { BmlLushu } from "vue-baidu-map";
import ProgressBar from "@/components/progress-bar";
import vueSeamlessScroll from "vue-seamless-scroll";
import EasyPlayer from "@easydarwin/easyplayer";
import startPointImg from "@/assets/images/start.png";
import endPointImg from "@/assets/images/end.png";
import pdf from "vue-pdf";
export default {
  components: {
    swiper,
    swiperSlide,
    BmlLushu,
    ProgressBar,
    vueSeamlessScroll,
    EasyPlayer,
    pdf,
  },
  data() {
    return {
      title: "进度",
      rightSize: "100",
      progre: 0,
      listData: [],
      optionSingleHeight: {
        step: 0.5, //数值越大滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.list
        hoverStop: true, //是否开启鼠标悬停stop
        // direction: 1, //0向下1向上2向左3向右
        openWatch: true, //开启数据实时监控刷新dom
        // singleHeight: 26, //单步运动挺值得高度(默认值0是无缝不停止的滚动)//竖着
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动)//横着
        // waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
      swiperOptions: {
        loop: true,
        speed: 2000,
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },

        /*   effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }, */
        effect: "cube",
        grabCursor: true,
        cubeEffect: {
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        },
        // effect: "cards",
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 修改默认点击左右箭头样式
        // navigation: {
        // nextEl: ".swiper-button-next",
        //prevEl: ".swiper-button-prev",
        //},
      },
      swiperOptionsInfo: {
        direction: "vertical",
        initialSlide: 0,
        autoplay: true,
        loop: true,
        speed: 1000,
        slidesPerView: 1,
        //  ----------你想有几个图片同时展示在页面上就写几，每次平滑过渡一张图片
        spaceBetween: 0,
        observer: true,
        //  ----------原因同上
        observeParents: true,
        paginationClickable: true,
        autoplay: {
          disableOnInteraction: false,
        },
      },

      play: true,
      path: [],
      icon: {
        url: require("@/assets/images/box1.png"),
        size: { width: 52, height: 26 },
        opts: { anchor: { width: 27, height: 13 } },
      },
      bg: "./images/nature-1.jpg",
      imgList: [],
      stepImg: [
        {
          url: require("@/assets/images/steps/4-1.png"),
        },
        {
          url: require("@/assets/images/steps/5-2.png"),
        },
        {
          url: require("@/assets/images/steps/6-2.png"),
        },
      ],
      stepImgEnv: [
        {
          url: require("@/assets/images/steps/env_step1.png"),
        },
        {
          url: require("@/assets/images/steps/env_step2.png"),
        },
        {
          url: require("@/assets/images/steps/env_step3.png"),
        },
      ],
      keyStepImg: [
        {
          url: require("@/assets/images/steps/step1.png"),
        },
        {
          url: require("@/assets/images/steps/step2.png"),
        },
        {
          url: require("@/assets/images/steps/step3.png"),
        },
      ],
      imgData: [
        {
          id: 0,
          url: "https://www.kunming-biotech.com:8443/profile/img/2023/02/16/111a0f71-6420-49ae-8722-fce0ec95b92e.jpg",
          title: "发起方",
          size: "3.1MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "开",
          isOpen: "开",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 13:38:10",
        },
        {
          id: 1,
          url: "https://www.kunming-biotech.com:8443/profile/img/2023/02/17/a1b06a9c-26db-49f4-9370-422412578507.jpg",
          title: "关箱后",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 13:38:10",
        },
        {
          id: 2,
          url: "https://www.kunming-biotech.com:8443/profile/img/2023/02/17/a1b06a9c-26db-49f4-9370-422412578507.jpg",
          title: "运输时1",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 13:54:20",
        },
        {
          id: 3,
          url: "https://www.kunming-biotech.com:8443/profile/img/2023/02/17/a1b06a9c-26db-49f4-9370-422412578507.jpg",
          title: "运输时2",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 13:56:20",
        },
        {
          id: 4,
          url: "https://www.kunming-biotech.com:8443/profile/img/2023/02/17/a1b06a9c-26db-49f4-9370-422412578507.jpg",
          title: "运输时3",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 14:03:20",
        },
        {
          id: 5,
          url: "https://www.kunming-biotech.com:8443/profile/img/2023/02/17/a1b06a9c-26db-49f4-9370-422412578507.jpg",
          title: "运输时4",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 14:05:20",
        },
        {
          id: 6,
          url: "https://www.kunming-biotech.com:8443/profile/img/2023/02/17/a1b06a9c-26db-49f4-9370-422412578507.jpg",
          title: "开箱前",
          size: "1.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 14:20:10",
        },
        {
          id: 7,
          url: "https://www.kunming-biotech.com:8443/profile/upload/checkCloud/1/898604B51022C0438006/image/2023-01-10_15-47-59.jpg",
          title: "接收方",
          size: "2.1MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "开",
          isOpen: "开",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 14:22:10",
        },
      ],
      infoList: {},
      activeIndex: 0,
      setTimer: null,
      /*   startMark: {
        lat: 24.511640216773337,
        lng: 118.10322950548175,
      },
      endMark: { lat: 24.51082997470183, lng: 118.10550938314441 }, */
      startMark: {
        lat: 24.517753,
        lng: 118.112049,
      },
      endMark: { lat: 24.570915, lng: 118.100128 },
      box3dGif: require("@/assets/images/box_3d1.gif"),
      envBox: require("@/assets/images/env_box.png"),
      // box3dVideo: require("@/assets/video/box.mov"),
      img3dList: [
        require("@/assets/images/1.png"),
        require("@/assets/images/2.png"),
        require("@/assets/images/3.png"),
      ],
      bacMusic: require("../assets/audio/bacMusic.mp3"),
      infoSuccess: require("../assets/audio/infoSuccess.mp3"),
      infoSuccessUnder: require("../assets/audio/infoSuccess_u.mp3"),
      trackSuccess: require("../assets/audio/trackSuccess.mp3"),
      trackSuccessUnder: require("../assets/audio/trackSuccess_u.mp3"),
      keySuccess: require("../assets/audio/keySuccess.mp3"),
      rateSuccess: require("../assets/audio/rateSuccess.mp3"),
      willEnterArea: require("../assets/audio/willEnterArea.mp3"),
      bacAudio: null,
      infoAudio: null,
      trackSuccessAudio: null,
      keySuccessAudio: null,
      rateSuccessAudio: null,
      willEnterAreaAudio: null,
      option_column: {},
      normal1: require("@/assets/images/normal_1.png"),
      normal2: require("@/assets/images/normal_2.png"),
      normal3: require("@/assets/images/normal_3.png"),
      trackNormal: false,
      isResult: false,
      fileType: "E",
      underwrite: {},
      ePath: [],
      startPointImg: startPointImg,
      endPointImg: endPointImg,
      zoom: 14,
      startPointImg: startPointImg,
      endPointImg: endPointImg,
      center: { lng: 118.10943, lat: 24.54348 },
      workTask: {},
      sampleData: [],
      sampleImgData: [],
      sampleVideoData: [],
      pdfUrl:
        "https://www.kunming-biotech.com:8443/profile/upload/checkCloud/a15db09d21c0853c9b2a9f0a485a7ed2/20230419.pdf",
      pdfVisible: false,
      taskPDF: "",
      numPages: 1,
      pageNum: 1,
    };
  },
  computed: {
    endPoint() {
      if (this.ePath && this.ePath.length > 0) {
        return this.ePath[this.ePath.length - 1];
      }
      return null;
    },
    controlSpeed() {
      if (this.zoom < 5) {
        return 1000;
      }
      if (this.zoom >= 5 && this.zoom < 10) {
        return 400;
      }
      if (this.zoom >= 10 && this.zoom < 15) {
        return 100;
      }
      if (this.zoom >= 15 && this.zoom < 20) {
        return 40;
      }
      if (this.zoom >= 20) {
        return 20;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      // this.autoplay();
      this.activeIndex = 0;
    }, 1000);

    // this.startMark = JSON.parse(localStorage.getItem("startMark"));
    this.fileType = localStorage.getItem("fileType");
    // this.endMark = JSON.parse(localStorage.getItem("endMark"));

    if (this.fileType == "E") {
      this.trackInfo = JSON.parse(localStorage.getItem("trackInfo"));
      this.imgList = JSON.parse(localStorage.getItem("images"));
      this.startMark = JSON.parse(localStorage.getItem("startMark"));
      this.endMark = JSON.parse(localStorage.getItem("endMark"));
      this.listData = JSON.parse(localStorage.getItem("entrust"));
      this.ePath = JSON.parse(localStorage.getItem("path"));

      this.listData = [...this.listData];
      this.initEcharts();
    } else if (this.fileType == "U") {
      this.trackInfo = JSON.parse(localStorage.getItem("trackInfo"));
      this.imgList = JSON.parse(localStorage.getItem("images"));
      this.startMark = JSON.parse(localStorage.getItem("startMark"));
      this.endMark = JSON.parse(localStorage.getItem("endMark"));
      this.listData = JSON.parse(localStorage.getItem("entrust"));
      this.underwrite = JSON.parse(localStorage.getItem("underwrite"));
      this.underwrite.scanFace =
        "https://www.kunming-biotech.com:8443/profile/underwrite/89860486102070527588/2023/08/22/919e4c13-9ba8-4b95-bced-f6e61d8652ef.jpg";
      this.listData = [...this.listData];
      this.initEcharts();
    } else {
      this.fileType = "V";
      this.workTask = JSON.parse(localStorage.getItem("workTask"));
      this.ePath = JSON.parse(localStorage.getItem("ePath"));
      this.listData = JSON.parse(localStorage.getItem("env"));
      this.taskPDF = localStorage.getItem("taskPDF");
      if (this.workTask.recordPDF) {
        this.getNumPages(this.workTask.recordPDF);
      }
      this.listData = [...this.listData];
      this.initEcharts();
    }
    this.initEcharts();
    /*   if (this.bacAudio == null) {
      this.bacAudiPlayer();
    } */
    setTimeout(() => {
      this.infoSuccessPlayer();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.setTimer);
    this.activeIndex = 0;
    // this.bacAudio.pause();
    /*   this.infoAudio.pause();
    this.trackSuccessAudio.pause();
    this.keySuccessAudio.pause();
    this.rateSuccessAudio.pause(); */
    this.infoAudio = null;
    this.trackSuccessAudio = null;
    this.trackSuccessAudio = null;
    this.rateSuccessAudio = null;

    if (this.infoAudio != null) {
      this.infoAudio = null;
    }
    if (this.trackSuccessAudio != null) {
      this.trackSuccessAudio = null;
    }
    if (this.keySuccessAudio != null) {
      this.trackSuccessAudio = null;
    }
    if (this.rateSuccessAudio != null) {
      this.rateSuccessAudio = null;
    }
  },
  methods: {
    getNumPages(url) {
      let loadingTask = pdf.createLoadingTask(url);
      loadingTask.promise
        .then((pdf) => {
          console.log(pdf.numPages);
          this.numPages = pdf.numPages;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 上一页
    prePage() {
      let page = this.pageNum;
      page = page > 1 ? page - 1 : this.numPages;
      this.pageNum = page;
    },

    // 下一页
    nextPage() {
      let page = this.pageNum;
      page = page < this.numPages ? page + 1 : 1;
      this.pageNum = page;
    },
    openPdf() {
      this.pdfVisible = true;
    },
    handleMapData() {
      this.center = this.ePath[0];
      this.play = true;
    },
    handler({ BMap, map }) {
      // 自动获取展示的比例
      var view = map.getViewport(eval(this.path));
      this.zoom = view.zoom;
      // console.log(this.zoom,888)
    },
    // 覆盖物的属性发生变化时触发
    updatePolylinePath(e) {
      this.path = e.target.getPath();
    },
    bacAudiPlayer() {
      this.bacAudio = new Audio(this.bacMusic);
      this.bacAudio.play();
      this.bacAudio.addEventListener("ended", () => {
        this.bacAudio.play();
      });
    },
    infoSuccessPlayer() {
      var url = this.infoSuccess;
      if (this.fileType == "U" || this.fileType == "V") {
        url = this.infoSuccessUnder;
      }
      this.infoAudio = new Audio(url);
      this.infoAudio.play();
      this.infoAudio.addEventListener("ended", () => {
        this.activeIndex = 1;
        setTimeout(() => {
          this.trackSuccessPlayer();
        }, 10000);
      });
    },
    trackSuccessPlayer() {
      var url = this.trackSuccess;
      if (this.fileType == "U" || this.fileType == "V") {
        url = this.trackSuccessUnder;
      }
      this.trackSuccessAudio = new Audio(url);
      this.trackSuccessAudio.play();
      setTimeout(() => {
        this.trackNormal = true;
      }, 1000);
      this.trackSuccessAudio.addEventListener("ended", () => {
        this.activeIndex = 2;
        setTimeout(() => {
          this.keySuccessAudioPlayer();
        }, 15000);
      });
    },
    keySuccessAudioPlayer() {
      this.trackNormal = false;
      this.keySuccessAudio = new Audio(this.keySuccess);
      this.keySuccessAudio.play();
      this.keySuccessAudio.addEventListener("ended", () => {
        this.activeIndex = 3;
        setTimeout(() => {
          this.rateSuccessPlayer();
        }, 5000);
      });
    },
    rateSuccessPlayer() {
      this.rateSuccessAudio = new Audio(this.rateSuccess);
      this.rateSuccessAudio.play();
      setTimeout(() => {
        this.isResult = true;
      }, 1000);
      this.rateSuccessAudio.addEventListener("ended", () => {
        setTimeout(() => {
          this.goMenu();
        }, 2000);
      });
    },

    handleScrollEnd() {
      // console.log("一次滚动结束");
      // this.infoSuccessPlayer();
    },
    goMenu() {
      this.$router.push("/menu");
    },
    reset() {
      this.play = true;
    },
    handleSearchComplete(res) {
      this.path = res.getPlan(0).getRoute(0).getPath();
    },
    autoplay() {
      console.log(this.activeIndex);
      this.setTimer = setInterval(() => {
        if (this.progre == 100) {
          clearInterval(this.setTimer);
          this.nextPage();
        } else {
          this.progre++;
        }
      }, 100);
    },
    nextPage() {
      this.reset();
      this.progre = 0;
      clearInterval(this.setTimer);
      if (this.activeIndex < 3) {
        this.activeIndex++;
        if (this.activeIndex == 1) {
          this.handleMapData();
        }
        setTimeout(() => {
          // this.autoplay();
        }, 1000);
      } else {
        this.activeIndex = 3;
        clearInterval(this.setTimer);
        // console.log("跳转到分类单点");
        this.$router.push("/menu");
      }
    },
    prevPage() {
      this.reset();
      this.progre = 0;
      if (this.activeIndex > 0) {
        this.activeIndex--;
        // this.autoplay();
      } else {
        this.activeIndex = 0;
      }
    },
    initEcharts() {
      this.option_column = {
        title: {
          text: "",
          target: "blank",
          textAlgin: "left",
        },
        tooltip: {}, //提示层
        legend: {
          data: ["name1"],
        },
        radar: {
          name: {
            textStyle: {
              color: "#fff", //字体颜色
              backgroundColor: "#bc4458", //背景色
              borderRadius: 3, //圆角
              padding: [3, 5], //padding
              fontSize: 18,
            },
          },
          // splitNumber: 5,
          center: ["50%", "50%"],
          radius: "80%",
          axisLine: {
            lineStyle: {
              width: 5,
            },
          },
          splitLine: {
            // (这里是指所有圆环)坐标轴在 grid 区域中的分隔线。
            lineStyle: {
              // color: "#fff",
              // 分隔线颜色
              width: 5,
              // 分隔线线宽
            },
          },
          startAngle: 270,
          indicator: [
            {
              name: "规范度",
              max: 100,
            },
            {
              name: "优化度",
              max: 100,
            },
            {
              name: "效能度",
              max: 100,
            },
            {
              name: "证据化",
              max: 100,
            },
            {
              name: "风险性",
              max: 100,
            },
          ],

          /*  // 图表背景的颜色
          splitArea: {
            show: false,
            areaStyle: {
              color: "red",
            },
          }, */
        },

        /*   splitLine: {
          lineStyle: {
            color: "red",
          },
        }, */
        series: [
          {
            name: "评价结果名字",
            type: "radar",
            itemStyle: {
              normal: {
                // 普通状态时的样式
                lineStyle: {
                  width: 5,
                  color: "red",
                },
                // opacity: 0.2,
              },
            },
            areaStyle: {
              color: "#00B386",
              opacity: 0.5,
            },
            data: [
              {
                value: [100, 90, 100, 100, 95],
                name: "评价结果",
              },
            ],
          },
        ],
      };
    },
  },
};
</script>
<style scoped lang="scss">
.box {
  width: 1920px;
  height: 900px;
  margin: 0 auto;
  .content {
    width: 100%;
    height: 900px;
    // margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .item_box {
      padding: 10px;
      width: 60%;
      // max-width: 635px;
      height: 90%;
      border-radius: 20px;
      background-color: rgba(56, 142, 223, 0.384);
      position: relative;
      display: flex;
      .process {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      .btn {
        width: 50px;
        height: 50px;
        color: #fff;
        text-align: center;
        line-height: 50px;
        position: absolute;
        bottom: 45%;
        // font-size: 30px;
        // margin: 0 10px;
        cursor: pointer;
      }
      .left {
        background: url("../assets/images/turn_left.png") no-repeat center 0px;
        background-position: center 0px;
        background-size: cover;
        left: 0;
      }
      .right {
        background: url("../assets/images/turn_right.png") no-repeat center 0px;
        background-position: center 0px;
        background-size: cover;
        right: 0;
      }
      .item1 {
        // padding: 10px;
        width: 100%;
        // max-width: 635px;
        height: 100%;
        /* border: 1px solid #fff; */

        overflow: hidden;
        /deep/ .dv-border-box-1 .border-box-content,
        /deep/ .dv-border-box-11 .border-box-content {
          border: 1px solid transparent !important;
        }
        /deep/ .dv-border-box-11 .dv-border-box-11-title {
          font-size: 24px;
        }
        .bac {
          width: 70%;
          height: 100%;
          background-repeat: no-repeat !important;
          background-size: cover !important;
          background-position: center !important;
          background-attachment: fixed !important;
        }
      }
    }
    .img_3d {
      // background-color: aqua;
      width: 35%;
      height: 90%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .swiper-box {
        width: 500px;
        height: 500px;
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
        .wrapper {
          height: 100%;
          .swiper-container {
            height: 100%;
            .swiper-slide {
              height: 100%;
              background-color: rgba(21, 71, 179, 0.418);
              color: white;
              display: flex;
              .bac {
                width: 70% !important;
                height: 100% !important;
                /* background: url("./images/nature-4.jpg"); */
                /* opacity: .9; */
                background-repeat: no-repeat !important;
                background-size: cover !important;
                background-position: center !important;
                background-attachment: fixed !important;
              }
              .img_info {
                width: 30%;
                height: 100%;
                // background-color: red;
                color: #fff;
              }
            }
          }
        }
      }
    }
    .item {
      padding: 10px;
      width: 40%;
      max-width: 635px;
      height: 40%;
      /* border: 1px solid #fff; */
      border-radius: 20px;
      background-color: rgba(56, 142, 223, 0.384);
      overflow: hidden;
      /deep/ .dv-border-box-1 .border-box-content,
      /deep/ .dv-border-box-11 .border-box-content {
        border: 1px solid transparent !important;
      }
      .bac {
        width: 100%;
        height: 100%;
      }
    }
    .user_info {
      .track_time {
        display: none;
      }
      .user {
        width: 90%;
        height: 100%;
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
        color: #fff;
        .item_li {
          height: 23%;
          li {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 46px;
            font-weight: 900;
          }
        }
        /* background-color: aqua; */
        .seamless-warp {
          height: 60%;
          overflow: hidden;
          .item_li {
            li {
              padding: 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 46px;
              font-weight: 900;
            }
          }
        }
        .swiper-box {
          width: 90%;
          height: 100%;
          margin-top: 60px;
          margin-left: auto;
          margin-right: auto;
          .wrapper {
            height: 100%;
            .swiper-container {
              height: 100%;
            }
          }
        }
      }
    }

    .video {
      video {
        height: 75%;
        margin-top: 60px;
      }
    }
    .track {
      position: relative;
      .track_time {
        position: absolute;
        width: 400px;
        height: 200px;
        // background-color: #fff;
        color: #000;
        font-size: 30px;
        color: blue;
        font-weight: 700;
        top: 12%;
        right: 80px;
        z-index: 99;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
      .track_info {
        width: 1037px;
        height: 60px;
        background-color: rgba(0, 132, 255, 0.486);
        position: absolute;
        font-size: 20px;
        color: #fff;
        bottom: 29px;
        left: 59px;
        z-index: 99;
      }
    }
    .step {
      .flex {
        .swiper-box_step {
          width: 1150px;
          height: 650px;
          margin-top: 60px;
          margin-left: auto;
          margin-right: auto;
          // width: 90%;
          // height: 80%;
          // margin-top: 60px;
          // margin-left: auto;
          // margin-right: auto;
          .wrapper_step {
            height: 650px !important;
            border-radius: 30px;
            background-color: #3369bbb0;
            ::v-deep .el-carousel {
              height: 100% !important;
              .el-carousel__container {
                height: 650px !important;
                .el-carousel__item {
                  height: 100% !important;
                  img {
                    width: 1150px;
                    // height: 100%;
                  }
                }
              }
            }

            .swiper-container {
              height: 100%;
              .swiper-slide {
                img {
                  width: 90%;
                }
              }
            }
          }
        }
      }
    }
    .image {
      .track_time {
        display: none !important;
      }
      .swiper-box {
        width: 99%;
        height: 100%;
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: brown; */
        .wrapper {
          height: 90%;
          border: 1px solid transparent;
          .swiper-container {
            height: 90%;
            margin-top: 40px;
            .swiper-slide {
              height: 100%;
              background-color: rgba(21, 71, 179, 0.418);
              color: white;
              display: flex;
              .bac {
                width: 50% !important;
                height: 100% !important;
                /* background: url("./images/nature-4.jpg"); */
                /* opacity: .9; */
                background-repeat: no-repeat !important;
                background-size: cover !important;
                background-position: center !important;
                background-attachment: fixed !important;
                .swiper_img {
                  width: 100%;
                  height: 100%;
                }
              }
              .img_info {
                width: 50%;
                height: 100%;
                // background-color: red;
                color: #fff;
                .title {
                  font-size: 40px;
                  font-weight: 800;
                  line-height: 20%;
                }
                .notice {
                  /* font-size: 40px;
                  font-weight: 800;
                  color: yellowgreen;
                  line-height: 20%; */
                  width: 300px;
                  height: 150px;
                  margin: 0 auto;
                  /* font-size: 40px;
                  font-weight: 800;
                  color: yellowgreen;
                  line-height: 20%; */
                  img {
                    height: 100%;
                    object-fit: contain;
                  }
                }
              }
            }
          }
        }
      }
      .sweiper_key {
        width: 1150px;
        height: 650px;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
        // background-color: aqua;
        background-color: #3369bbb0;
        border-radius: 30px;

        ::v-deep .el-carousel {
          height: 100% !important;
          .el-carousel__container {
            height: 650px !important;
            .el-carousel__item {
              height: 100% !important;
              display: flex;
              .title {
                width: 300px;
                height: 100%;
                img {
                  // width: 900px;
                  width: 300px;
                }
              }
              .info {
                margin-top: 20px;
                margin-right: 20px;
                width: 700px;
                height: 100%;
                font-size: 28px;
                font-weight: 700;
                color: #fff;
                .sample {
                  display: flex;
                  justify-content: space-between;
                }
                .info_item {
                  margin-top: 5px;
                  display: flex;
                  justify-content: space-between;
                  .key_video {
                    width: 300px;
                    height: 300px;

                    .video {
                      width: 300px;
                      height: 300px;
                      transform: rotate(90deg);
                    }
                  }
                  .sample_video {
                    width: 150px;
                    height: 150px;
                    .video {
                      width: 150px;
                      height: 150px;
                      // transform: rotate(90deg);
                    }
                  }
                }
              }
              .sample_info {
                width: 100%;
                margin-left: 20px;
                .sample_item {
                  width: 40%;
                }
              }
            }
          }
        }
      }
    }
    .map {
      height: 90%;
      width: 90%;
      margin: 50px auto;
      border-radius: 10px;
    }
    .sample_map {
      height: 590px;
      width: 590px;
      // margin: 50px auto;
      border-radius: 10px;
    }
    .evaluate {
      position: relative;
      .rate {
        position: absolute;
        top: 0;
        left: -20px;
        height: 80%;
        width: 90%;
        margin: 0px auto;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .rate_item {
          width: 80px;
          height: 80px;
          // border: 1px solid #000;
          color: #fff;
          // background-color: aqua;
          .rate_sign {
            width: 50px;
            height: 50px;
            margin: 0 auto;
            img {
              width: 100%;
            }
          }
        }
      }
      .result {
        position: absolute;
        width: 300px;
        height: 300px;
        // background-color: #fff;
        right: 20px;
        bottom: 20px;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .under {
    width: 40%;
    display: flex;

    // justify-content: space-between;
    .btn {
      width: 50px;
      height: 50px;
      color: #fff;
      text-align: center;
      line-height: 50px;

      // font-size: 30px;
      // margin: 0 10px;
      cursor: pointer;
    }
    .left {
      background: url("../assets/images/left.png") no-repeat center 0px;
      background-position: center 0px;
      background-size: cover;
    }
    .right {
      background: url("../assets/images/right.png") no-repeat center 0px;
      background-position: center 0px;
      background-size: cover;
    }
    .process {
      width: 80%;
    }
  }

  .op {
    width: 40%;
    display: flex;
    justify-content: space-between;
    .btn {
      width: 50px;
      height: 50px;
      color: #fff;
      text-align: center;
      line-height: 50px;

      // font-size: 30px;
      // margin: 0 10px;
      cursor: pointer;
    }
    .left {
      background: url("../assets/images/left.png") no-repeat center 0px;
      background-position: center 0px;
      background-size: cover;
    }
    .right {
      background: url("../assets/images/right.png") no-repeat center 0px;
      background-position: center 0px;
      background-size: cover;
    }
  }
}
// 去除百度地图的图标 根据实际情况看是否要加样式穿透(::v-deep)
::v-deep .anchorBL {
  display: none !important;
}
/deep/ .key_video .vjs-tech {
  transform: rotate(90deg);
}
/deep/ .el-image__inner {
  background-color: #fff !important;
}
.border_bottom {
  border-bottom: 6px solid #e6e6e6;
}
</style>
