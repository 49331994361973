<template>
  <div class="box">
    <!-- <h1>自动播放内容，播放结束后跳转到分类单点页面</h1> -->
    <div class="content">
      <div class="item user_info">
        <dv-border-box-11 title="用户信息">
          <div class="user">
            <div class="swiper-box">
              <div class="wrapper">
                <swiper ref="mySwiper" v-bind:options="swiperOptionsInfo">
                  <swiper-slide
                    v-for="(item, index) in imgList"
                    v-bind:key="index"
                  >
                    <div
                      class="bac"
                      :style="{ background: ' url(' + item.url + ')' }"
                    ></div>
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </dv-border-box-11>
      </div>
      <div class="item track">
        <dv-border-box-11 title="物证轨迹">
          <baidu-map
            class="map"
            :center="{ lng: 116.404, lat: 39.915 }"
            :zoom="5"
          >
            <bm-driving
              start="天安门"
              end="百度大厦"
              @searchcomplete="handleSearchComplete"
              :panel="false"
              :autoViewport="true"
            ></bm-driving>
            <bml-lushu
              @stop="reset"
              :path="path"
              :icon="icon"
              :play="play"
              :rotation="true"
            >
            </bml-lushu>
          </baidu-map>
        </dv-border-box-11>
      </div>
      <div class="item video">
        <dv-border-box-11 title="视频">
          <video
            src="https://www.w3schools.com/html/movie.mp4"
            controls
            :autoplay="true"
            :muted="true"
            :loop="true"
            :playsinline="true"
            :webkit-playsinline="true"
          ></video>
        </dv-border-box-11>
      </div>
      <div class="item image">
        <dv-border-box-11 title="照片">
          <div class="swiper-box">
            <div class="wrapper">
              <swiper ref="mySwiper" v-bind:options="swiperOptions">
                <swiper-slide
                  v-for="(item, index) in imgList"
                  v-bind:key="index"
                >
                  <div
                    class="bac"
                    :style="{ background: ' url(' + item.url + ')' }"
                  ></div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </dv-border-box-11>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import { BmlLushu } from "vue-baidu-map";
export default {
  components: {
    swiper,
    swiperSlide,
    BmlLushu,
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        speed: 2000,
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        /*   effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }, */
        effect: "cube",
        grabCursor: true,
        cubeEffect: {
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        },
        // effect: "cards",
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 修改默认点击左右箭头样式
        // navigation: {
        // nextEl: ".swiper-button-next",
        //prevEl: ".swiper-button-prev",
        //},
      },
      swiperOptionsInfo: {
        direction: "vertical",
        initialSlide: 0,
        autoplay: true,
        loop: true,
        speed: 1000,
        slidesPerView: 1,
        //  ----------你想有几个图片同时展示在页面上就写几，每次平滑过渡一张图片
        spaceBetween: 0,
        observer: true,
        //  ----------原因同上
        observeParents: true,
        paginationClickable: true,
        autoplay: {
          disableOnInteraction: false,
        },
      },

      play: true,
      path: [],
      icon: {
        url: "http://api.map.baidu.com/library/LuShu/1.2/examples/car.png",
        size: { width: 52, height: 26 },
        opts: { anchor: { width: 27, height: 13 } },
      },
      bg: "./images/nature-1.jpg",
      imgList: [
        {
          id: 1,
          url: require("./images/nature-1.jpg"),
        },
        {
          id: 2,
          url: require("./images/nature-2.jpg"),
        },
        {
          id: 3,
          url: require("./images/nature-3.jpg"),
        },
        {
          id: 4,
          url: require("./images/nature-4.jpg"),
        },
        {
          id: 5,
          url: require("./images/nature-5.jpg"),
        },
      ],
      infoList: {},
    };
  },
  mounted() {
    /*   new Swiper(".swiper-container", {
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      loop: true,
      // 如果需要分页器
      pagination: ".swiper-pagination",
      // 如果需要前进后退按钮
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      // 如果需要滚动条
      scrollbar: ".swiper-scrollbar",
    }); */
  },
  methods: {
    reset() {
      this.play = false;
    },
    handleSearchComplete(res) {
      this.path = res.getPlan(0).getRoute(0).getPath();
    },
  },
};
</script>
<style scoped lang="scss">
.box {
  width: 100%;
  height: 90vh;
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .item {
      padding: 10px;
      width: 40%;
      max-width: 635px;
      height: 40%;
      /* border: 1px solid #fff; */
      border-radius: 20px;
      background-color: rgba(56, 142, 223, 0.384);
      overflow: hidden;
      /deep/ .dv-border-box-1 .border-box-content,
      /deep/ .dv-border-box-11 .border-box-content {
        border: 1px solid transparent !important;
      }
      .bac {
        width: 100%;
        height: 100%;
      }
    }
    .user {
      width: 90%;
      height: 70%;
      margin-top: 60px;
      margin-left: auto;
      margin-right: auto;
      color: #fff;
      /* background-color: aqua; */
      .swiper-box {
        width: 90%;
        height: 100%;
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
        .wrapper {
          height: 100%;
          .swiper-container {
            height: 100%;
          }
        }
      }
    }
    .video {
      video {
        height: 75%;
        margin-top: 60px;
      }
    }
    .image {
      .swiper-box {
        width: 50%;
        height: 100%;
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
        /* background-color: brown; */
        .wrapper {
          height: 85%;
          .swiper-container {
            height: 85%;
            .swiper-slide {
              height: 100%;
              background-color: rgba(3, 57, 173, 0.493);
              color: white;
              .bac {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
    .map {
      height: 80%;
      width: 90%;
      margin: 50px auto;
      border-radius: 10px;
    }
  }
}
</style>
