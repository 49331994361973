<template>
  <div class="box">
    <div class="light">
      <div class="logo">
        <img src="../assets/logo3.png" alt="" />
      </div>
      <div class="dots">
        <div class="dot_item">
          <div :class="{ dot: true, dot1: dot1Show, dot2: redShow1 }"></div>
          <div :class="{ dot: true, dot1: dot2Show, dot2: redShow2 }"></div>
          <div :class="{ dot: true, dot1: dot3Show, dot2: redShow3 }"></div>
          <div :class="{ dot: true, dot1: dot4Show, dot2: redShow4 }"></div>
          <div :class="{ dot: true, dot1: dot5Show, dot2: redShow5 }"></div>
          <div :class="{ dot: true, dot1: dot6Show, dot2: redShow6 }"></div>
          <div :class="{ dot: true, dot1: dot7Show, dot2: redShow7 }"></div>
        </div>
        <!-- <div class="dot_item">
          <div :class="{ dot: true, dot1: dot6Show }"></div>
          <div :class="{ dot: true, dot1: dot7Show }"></div>
          <div :class="{ dot: true, dot1: dot8Show }"></div>
          <div :class="{ dot: true, dot1: dot9Show }"></div>
          <div :class="{ dot: true, dot1: dot10Show }"></div>
        </div> -->
      </div>
    </div>
    <div class="flow">
      <div class="upload">
        <dv-border-box-1>
          <div :class="{ up: true, success_up: upSuccess, err_up: upErr }">
            <input class="input" type="file" @change="changeFile($event)" />
            <div class="info">
              {{ fileName }}
            </div>
          </div>
        </dv-border-box-1>
      </div>

      <transition name="arrow">
        <div class="next" v-if="showCheck">
          <div class="arrow">
            <div class="right_arrow"></div>
          </div>
          <div class="upload">
            <dv-border-box-1>
              <div class="up">
                <div class="info" v-if="!checkFinish">
                  检测完整性中<i class="el-icon-loading"></i>
                </div>
                <div class="info" v-if="checkFinish">文件完整</div>
              </div>
            </dv-border-box-1>
          </div>
        </div>
      </transition>
      <transition name="arrow">
        <div class="next" v-if="showOp">
          <div class="arrow">
            <div class="right_arrow"></div>
          </div>
          <div class="upload">
            <dv-border-box-1>
              <div class="up">
                <div class="info" v-if="!opFinish">
                  校验中<i class="el-icon-loading"></i>
                </div>
                <div class="info" v-if="opFinish">
                  校验完成
                  <br />
                  即将自动播放数据
                </div>
              </div>
            </dv-border-box-1>
          </div>
        </div>
      </transition>
    </div>
    <div class="enter_tips" v-show="enterTips">即将进行物证场景还原......</div>
    <!-- <button @click="changeDots">点击</button> -->
  </div>
</template>
<script>
import { getFile } from "@/api/file";
import { wgs2gcj, gcj2bd } from "@/utils/transform.js";

export default {
  data() {
    return {
      fileName: "请上传文件",
      showCheck: false,
      checkFinish: false,
      showOp: false,
      opFinish: false,
      file: null,
      evidenceData: null,
      startMark: {
        lat: 24.517753,
        lng: 118.112049,
      },
      endMark: { lat: 24.570915, lng: 118.100128 },
      hash: "",
      images: [],
      entrust: null,
      dot1Show: false,
      dot2Show: false,
      dot3Show: false,
      dot4Show: false,
      dot5Show: false,
      dot6Show: false,
      dot7Show: false,
      dot8Show: false,
      dot9Show: false,
      dot10Show: false,
      redShow1: false,
      redShow2: false,
      redShow3: false,
      redShow4: false,
      redShow5: false,
      redShow6: false,
      redShow7: false,
      activeDot: 1,
      checkSuccessUrl: require("@/assets/audio/evidence_check_succes.mp3"),
      checkFailUrl: require("@/assets/audio/evidence_check_fail.mp3"),
      nodeCheck: require("@/assets/audio/node_check.mp3"),
      nodeCheckSuccess: require("@/assets/audio/node_check_success.mp3"),
      nodeCheckFail: require("@/assets/audio/node_check_fail.mp3"),
      upSuccess: false,
      upErr: false,
      enterTips: false,
      imgData: [
        {
          id: 0,
          url: "https://www.kunming-biotech.com:8443/profile/img/2023/02/16/111a0f71-6420-49ae-8722-fce0ec95b92e.jpg",
          title: "发起方",
          size: "3.1MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "开",
          isOpen: "开",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 13:38:10",
        },
        {
          id: 1,
          url: "https://www.kunming-biotech.com:8443/profile/img/2023/02/17/a1b06a9c-26db-49f4-9370-422412578507.jpg",
          title: "关箱后",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 13:38:10",
        },
        {
          id: 2,
          url: "https://www.kunming-biotech.com:8443/profile/img/2023/02/17/a1b06a9c-26db-49f4-9370-422412578507.jpg",
          title: "运输时1",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 13:54:20",
        },
        {
          id: 3,
          url: "https://www.kunming-biotech.com:8443/profile/img/2023/02/17/a1b06a9c-26db-49f4-9370-422412578507.jpg",
          title: "运输时2",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 13:56:20",
        },
        {
          id: 4,
          url: "https://www.kunming-biotech.com:8443/profile/img/2023/02/17/a1b06a9c-26db-49f4-9370-422412578507.jpg",
          title: "运输时3",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 14:03:20",
        },
        {
          id: 5,
          url: "https://www.kunming-biotech.com:8443/profile/img/2023/02/17/a1b06a9c-26db-49f4-9370-422412578507.jpg",
          title: "运输时4",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 14:05:20",
        },
        {
          id: 6,
          url: "https://www.kunming-biotech.com:8443/profile/img/2023/02/17/a1b06a9c-26db-49f4-9370-422412578507.jpg",
          title: "开箱前",
          size: "1.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 14:20:10",
        },
        {
          id: 7,
          url: "https://www.kunming-biotech.com:8443/profile/upload/checkCloud/1/898604B51022C0438006/image/2023-01-10_15-47-59.jpg",
          title: "接收方",
          size: "2.1MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "开",
          isOpen: "开",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023/07/04 14:22:10",
        },
      ],
      underwriteImgData: [
        {
          id: 0,
          url: "https://www.kunming-biotech.com:8443/profile/underwrite/89860486102070527589/2023/04/18/6f4f3914-7f8d-43ab-88f0-7bb5096f45e6.png",
          title: "身份证照片",
          size: "3.1MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "开",
          isOpen: "开",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023-05-18 15:16:13",
        },
        {
          id: 1,
          url: "https://www.kunming-biotech.com:8443/profile/underwrite/89860486102070527589/2023/04/18/dfe261d4-4d8f-491c-833c-544e89be658d.jpg",
          title: "人脸扫描",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023-05-18 15:36:13",
        },
        {
          id: 2,
          url: "https://www.kunming-biotech.com:8443/profile/underwrite/89860486102070527589/2023/04/18/4629af37-bd52-4c11-9f69-90f98846a805.png",
          title: "指纹",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023-05-18 15:41:13",
        },
        {
          id: 3,
          url: "https://www.kunming-biotech.com:8443/profile/underwrite/89860486102070527589/2023/04/18/faf91e35-a44d-44a1-a185-7442c8feb480.png",
          title: "文件确认签名",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023-05-18 15:42:13",
        },
        {
          id: 4,
          url: "https://www.kunming-biotech.com:8443/profile/underwrite/2023/04/18/48b6ccb0-1b78-40f9-9005-748645f1cdbc.png",
          title: "扫描文件签名",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023-05-18 15:45:13",
        },
        {
          id: 5,
          url: "https://www.kunming-biotech.com:8443/profile/underwrite/2023/04/18/b89fa512-78c6-4b85-ba74-4695987d3c4b.jpg",
          title: "核保扫描文件",
          size: "2.3MB",
          cn: "640×480",
          level: "96dpi",
          vertical: "96dpi",
          bits: "24",
          compress: "否",
          isLock: "关",
          isOpen: "关",
          temperature: "27℃",
          pressure: "101.53",
          batteryPercentage: "70%",
          time: "2023-05-18 15:46:13",
        },
      ],
      trackInfo:
        "物证轨迹开始时间：2023/07/04 13:38:10 物证轨迹结束时间：2023/07/0414:20:10 物证轨迹开始地点：厦门市湖里区湖里大道35号物证轨迹结束地点：厦门市集美区鳌园路华侨大学西南门",
      fileType: "E",
      underwrite: {},
      code: 200,
      mapData: [],
      sampleData: [],
    };
  },
  methods: {
    changeFile(e) {
      // console.log(e);
      this.resetDot();
      let checkAudio = new Audio(this.checkSuccessUrl);
      let failAudio = new Audio(this.checkFailUrl);
      let formData = new FormData();
      let file = e.target.files;
      if (file.length < 0) {
        console.log("没有选择文件");
        return false;
      } else if (!/\.(wzy)$/.test(file[0].name.toLowerCase())) {
        this.$message({
          message: "上传文件格式不对！！",
          type: "error",
        });
        return false;
      } else {
        this.fileName = file[0].name;
        console.log(this.fileName);
        // console.log(file[0]);
        this.file = file[0];
        formData.append("file", file[0]);
        localStorage.clear();

        getFile(formData).then((res) => {
          console.log(res);
          let resData = res.data;
          this.code = resData.code;
          if (resData.code == 200) {
            if (resData.type == "E") {
              this.fileType = resData.type;
              this.hash = resData.hash;
              // this.images = resData.images;
              if (resData.entrust) {
                this.entrust = this.resetEntrust(resData.entrust);
                localStorage.setItem("entrust", JSON.stringify(this.entrust));
              }
              localStorage.setItem("hash", this.hash);
              localStorage.setItem("fileType", this.fileType);
              localStorage.setItem(
                "images",
                JSON.stringify(
                  this.resetEnvImage([...resData.images, ...resData.endImg])
                )
              );

              localStorage.setItem("trackInfo", JSON.stringify(this.trackInfo));
              localStorage.setItem("status", JSON.stringify(resData.status));
              localStorage.setItem("endMark", JSON.stringify(this.endMark));
              localStorage.setItem(
                "path",
                JSON.stringify(this.resetEntPath(resData.map))
              );
              /*   checkAudio.play();
              setTimeout(() => {
                this.changeDots();
              }, 5000); */
            } else if (resData.type == "U") {
              let entrust = this.resetUnderWriter(resData.underwrite);
              this.underwrite = resData.underwrite;
              localStorage.setItem("entrust", JSON.stringify(entrust));
              localStorage.setItem(
                "underwrite",
                JSON.stringify(this.underwrite)
              );
              this.fileType = resData.type;
              this.hash = resData.hash;
              localStorage.setItem("hash", this.hash);
              localStorage.setItem("fileType", this.fileType);
            } else if (resData.type == "V") {
              // 环保监测
              console.log(resData);
              this.fileType = resData.type;
              this.hash = resData.hash;
              localStorage.setItem("fileType", this.fileType);
              localStorage.setItem("hash", this.hash);
              localStorage.setItem("taskPDF", resData.taskPDF);

              localStorage.setItem(
                "env",
                JSON.stringify(this.resetEnv(resData.record))
              );
              localStorage.setItem("workTask", JSON.stringify(resData.record));
              this.mapData = this.resetEntPath(resData.record.evidenceMap);
              localStorage.setItem("ePath", JSON.stringify(this.mapData));
            }
            checkAudio.play();
            setTimeout(() => {
              this.changeDots();
            }, 5000);
            // this.changeCheck();
          } else if (resData.code == 201) {
            this.redShow1 = true;
            this.redShow2 = true;
            this.redShow3 = true;
            this.redShow4 = true;
            this.redShow5 = true;
            this.redShow6 = true;
            this.redShow7 = true;
            this.upErr = true;
            failAudio.play();
          } else {
            checkAudio.play();
            setTimeout(() => {
              this.changeDots();
            }, 5000);
          }
        });
      }
    },
    changeDots1() {
      // setTimeout(() => {
      this.dot1Show = true;
      this.dot2Show = false;
      this.dot3Show = false;
      this.dot4Show = false;
      this.dot5Show = false;
      this.dot6Show = false;
      this.dot7Show = false;
      // }, 500);
    },
    changeDots2() {
      // setTimeout(() => {
      this.dot1Show = false;
      this.dot2Show = true;
      this.dot3Show = false;
      this.dot4Show = false;
      this.dot5Show = false;
      this.dot6Show = false;
      this.dot7Show = false;
      // }, 500);
    },
    changeDots3() {
      // setTimeout(() => {
      this.dot1Show = false;
      this.dot2Show = false;
      this.dot3Show = true;
      this.dot4Show = false;
      this.dot5Show = false;
      this.dot7Show = false;
      this.dot6Show = false;
      // }, 500);
    },
    changeDots4() {
      // setTimeout(() => {
      this.dot1Show = false;
      this.dot2Show = false;
      this.dot3Show = false;
      this.dot4Show = true;
      this.dot5Show = false;
      this.dot6Show = false;
      this.dot7Show = false;
      // }, 500);
    },
    changeDots5() {
      // setTimeout(() => {
      this.dot1Show = false;
      this.dot2Show = false;
      this.dot3Show = false;
      this.dot4Show = false;
      this.dot5Show = true;
      this.dot6Show = false;
      this.dot7Show = false;
      // }, 500);
    },
    changeDots6() {
      // setTimeout(() => {
      this.dot1Show = false;
      this.dot2Show = false;
      this.dot3Show = false;
      this.dot4Show = false;
      this.dot5Show = false;
      this.dot6Show = true;
      this.dot7Show = false;
      // }, 500);
    },
    changeDots7() {
      // setTimeout(() => {
      this.dot1Show = false;
      this.dot2Show = false;
      this.dot3Show = false;
      this.dot4Show = false;
      this.dot5Show = false;
      this.dot6Show = false;
      this.dot7Show = true;
      // }, 500);
    },
    resetDot() {
      this.dot1Show = false;
      this.dot2Show = false;
      this.dot3Show = false;
      this.dot4Show = false;
      this.dot5Show = false;
      this.dot6Show = false;
      this.dot7Show = false;
      this.redShow1 = false;
      this.redShow2 = false;
      this.redShow3 = false;
      this.redShow4 = false;
      this.redShow5 = false;
      this.redShow6 = false;
      this.redShow7 = false;
      this.upSuccess = false;
      this.upErr = false;
      this.enterTips = false;
    },
    changeDots() {
      let nodeCheck = new Audio(this.nodeCheck);
      let nodeCheckSuccess = new Audio(this.nodeCheckSuccess);
      let nodeCheckFail = new Audio(this.nodeCheckFail);
      // 节点验证中
      nodeCheck.play();
      // return;
      let _this = this;
      let i = 0;
      let timer = setInterval(() => {
        i++;
        setTimeout(() => {
          // this.dot1Show = true;
          this.changeDots1();
          setTimeout(() => {
            // this.dot2Show = true;
            this.changeDots2();
            setTimeout(() => {
              // this.dot3Show = true;
              this.changeDots3();
              setTimeout(() => {
                // this.dot4Show = true;
                this.changeDots4();
                setTimeout(() => {
                  // this.dot5Show = true;
                  this.changeDots5();
                  setTimeout(() => {
                    // this.dot6Show = true;
                    this.changeDots6();
                    setTimeout(() => {
                      // this.dot7Show = true;
                      this.changeDots7();
                      // console.log(i);
                      if (i == 4 && _this.code == 200) {
                        _this.dot1Show = true;
                        _this.dot2Show = true;
                        _this.dot3Show = true;
                        _this.dot4Show = true;
                        _this.dot5Show = true;
                        _this.dot6Show = true;
                        _this.dot7Show = true;
                        _this.upSuccess = true;
                      }
                      /*    else if (i == 4 && _this.code == 201) {
                        _this.redShow1 = true;
                        _this.redShow2 = true;
                        _this.redShow3 = true;
                        _this.redShow4 = true;
                        _this.redShow5 = true;
                        _this.redShow6 = true;
                        _this.redShow7 = true;
                        _this.upErr = true;
                      } */
                      /*  if (i == 4 && this.fileName == "1.wzy") {
                        _this.dot1Show = true;
                        _this.dot2Show = true;
                        _this.dot3Show = true;
                        _this.dot4Show = true;
                        _this.dot5Show = true;
                        _this.dot6Show = true;
                        _this.dot7Show = true;
                        _this.upSuccess = true;
                      } else if (i == 4 && this.fileName == "0.wzy") {
                        _this.dot1Show = true;
                        _this.dot2Show = true;
                        _this.dot3Show = true;
                        _this.dot4Show = true;
                        _this.dot5Show = true;
                        _this.dot6Show = true;
                        _this.dot7Show = true;
                        _this.upSuccess = true;
                      } else if (
                        i == 4 &&
                        this.fileName == "银行保全(1673336733003).wzy"
                      ) {
                        _this.dot1Show = true;
                        _this.dot2Show = true;
                        _this.dot3Show = true;
                        _this.dot4Show = true;
                        _this.dot5Show = true;
                        _this.dot6Show = true;
                        _this.dot7Show = true;
                        _this.upSuccess = true;
                      } else if (
                        i == 4 &&
                        this.fileName == "物证保全(1673336733000).wzy"
                      ) {
                        _this.dot1Show = true;
                        _this.dot2Show = true;
                        _this.dot3Show = true;
                        _this.dot4Show = true;
                        _this.dot5Show = true;
                        _this.dot6Show = true;
                        _this.dot7Show = true;
                        _this.upSuccess = true;
                      } else if (
                        i == 4 &&
                        this.fileName == "环保监测(1673336733004).wzy"
                      ) {
                        _this.dot1Show = true;
                        _this.dot2Show = true;
                        _this.dot3Show = true;
                        _this.dot4Show = true;
                        _this.dot5Show = true;
                        _this.dot6Show = true;
                        _this.dot7Show = true;
                        _this.upSuccess = true;
                      } else if (
                        i == 4 &&
                        this.fileName == "物证保全(1673336733002).wzy"
                      ) {
                        _this.redShow1 = true;
                        _this.redShow2 = true;
                        _this.redShow3 = true;
                        _this.redShow4 = true;
                        _this.redShow5 = true;
                        _this.redShow6 = true;
                        _this.redShow7 = true;
                        _this.upErr = true;
                      } else if (i == 4 && this.fileName == "3.wzy") {
                        _this.redShow1 = true;
                        _this.redShow2 = true;
                        _this.redShow3 = true;
                        _this.redShow4 = true;
                        _this.redShow5 = true;
                        _this.redShow6 = true;
                        _this.redShow7 = true;
                        _this.upErr = true;
                      } */
                    }, 100);
                  }, 100);
                }, 100);
              }, 100);
            }, 100);
          }, 100);
        }, 100);
        if (i > 3) {
          clearInterval(timer);
          if (_this.code == 202) {
            // console.log("物证可信校验失败");
            nodeCheckFail.play();
            _this.redShow1 = true;
            _this.redShow2 = true;
            _this.redShow3 = true;
            _this.redShow4 = true;
            _this.redShow5 = true;
            _this.redShow6 = true;
            _this.redShow7 = true;
            _this.upErr = true;
          } else {
            nodeCheckSuccess.play();
            this.enterTips = true;
            nodeCheckSuccess.addEventListener("ended", () => {
              // console.log("跳转至自动播放页面");
              // return;
              this.$router.push("/autoPlay");
              // this.$router.push("/menu");
            });
          }
        }
      }, 700);
    },
    changeFailDots() {
      let nodeCheck = new Audio(this.nodeCheck);
      let nodeCheckSuccess = new Audio(this.nodeCheckSuccess);
      nodeCheck.play();
      let _this = this;
      let i = 0;
      let timer = setInterval(() => {
        i++;
        setTimeout(() => {
          // this.dot1Show = true;
          this.changeDots1();
          setTimeout(() => {
            // this.dot2Show = true;
            this.changeDots2();
            setTimeout(() => {
              // this.dot3Show = true;
              this.changeDots3();
              setTimeout(() => {
                // this.dot4Show = true;
                this.changeDots4();
                setTimeout(() => {
                  // this.dot5Show = true;
                  this.changeDots5();
                  setTimeout(() => {
                    // this.dot6Show = true;
                    this.changeDots6();
                    setTimeout(() => {
                      // this.dot7Show = true;
                      this.changeDots7();

                      /*  setTimeout(() => {
                      this.dot8Show = true;
                      setTimeout(() => {
                        this.dot9Show = true;
                        setTimeout(() => {
                          this.dot10Show = true;
                          nodeCheckSuccess.play();
                          setTimeout(() => {
                            this.$router.push("/autoPlay");
                          }, 4000);
                        }, 500);
                      }, 500);
                    }, 500); */
                    }, 100);
                  }, 100);
                }, 100);
              }, 100);
            }, 100);
          }, 100);
        }, 100);
        if (i > 3) {
          console.log("stop");
          clearInterval(timer);

          _this.dot1Show = true;
          _this.dot2Show = true;
          _this.dot3Show = true;
          _this.dot4Show = true;
          _this.dot5Show = true;
          _this.dot6Show = true;
          _this.dot7Show = true;
          nodeCheckSuccess.play();
          /*  setTimeout(() => {
            this.$router.push("/autoPlay");
          }, 4000); */
        }
      }, 700);
    },
    errTip() {
      this.$message({
        message: "文件损坏",
        type: "error",
      });
    },
    successTip() {
      this.$message({
        message: "成功",
        type: "success",
      });
    },
    changeCheck() {
      this.showCheck = true;
      setTimeout(() => {
        this.checkFinish = true;
        this.changeOp();
      }, 4000);
    },
    changeOp() {
      this.showOp = true;
      setTimeout(() => {
        this.opFinish = true;
        setTimeout(() => {
          this.$router.push("/autoPlay");
        }, 2000);
      }, 4000);
    },
    resetEntrust(entrust) {
      if (entrust) {
        let arr = [
          /*  {
            name: "物证保全编号",
            value: entrust.sendTime,
          }, */
          {
            name: "设备类型",
            value: "物证箱",
          },
          {
            name: "起点",
            value: "(118.103,24.511)",
          },
          {
            name: "终点",
            value: "(118.105,24.510)",
          },
          {
            name: "接收公司",
            value: entrust.receiverCompany,
          },
          {
            name: "接收方",
            value: entrust.receiverName,
          },
          {
            name: "发起公司",
            value: entrust.senderCompany,
          },
          {
            name: "发起方",
            value: entrust.senderName,
          },
          {
            name: "设备名称",
            value: entrust.deviceNoteName,
          },
          {
            name: "设备编号",
            value: entrust.deviceName,
          },
          {
            name: "物证类别",
            value: entrust.type,
          },
          {
            name: "接收方手机号",
            value: entrust.receiverPhone,
          },
          {
            name: "发起方手机号",
            value: entrust.senderPhone,
          },
        ];
        // console.log(arr);
        return arr;
      }
    },
    resetEnvImage(img) {
      if (img) {
        let len = img.length;
        let arr = [];
        img.forEach((i, index) => {
          let obj = {};
          if (index == 0 || index == len - 1) {
            obj = {
              id: index,
              url: i,
              size: "3.1MB",
              cn: "640×480",
              level: "96dpi",
              vertical: "96dpi",
              bits: "24",
              compress: "否",
              isLock: "开",
              isOpen: "开",
              temperature: "27℃",
              pressure: "101.53",
              batteryPercentage: "70%",
            };
          } else {
            obj = {
              id: index,
              url: i,
              size: "3.1MB",
              cn: "640×480",
              level: "96dpi",
              vertical: "96dpi",
              bits: "24",
              compress: "否",
              isLock: "关",
              isOpen: "关",
              temperature: "27℃",
              pressure: "101.53",
              batteryPercentage: "70%",
            };
          }
          arr.push(obj);
        });
        // console.log(arr);
        return arr;
      }
    },
    resetEntPath(path) {
      if (path) {
        let arr = [];
        path.forEach((item) => {
          let gcjPoint = wgs2gcj(item.latitude, item.longitude); //gps转火星
          let pointAfter = gcj2bd(gcjPoint.lat, gcjPoint.lng); //火星转百度
          let obj = {
            lng: pointAfter.lng,
            lat: pointAfter.lat,
          };
          arr.push(obj);
        });
        return arr;
      } else {
        return [];
      }
    },
    resetUnderWriter(underwrite) {
      if (underwrite) {
        let arr = [
          /*   {
            name: "核保保全编号",
            value: underwrite.createTime,
          }, */
          /* {
            name: "起点",
            value: "(118.103,24.511)",
          },
          {
            name: "终点",
            value: "(118.105,24.510)",
          }, */
          {
            name: "设备类型",
            value: "银行保",
          },
          {
            name: "核保箱备注名",
            value: underwrite.deviceNoteName,
          },
          {
            name: "核保箱设备名",
            value: underwrite.deviceName,
          },
          {
            name: "发起核保人姓名",
            value: underwrite.username,
          },
          {
            name: "发起核保人身份证号",
            value: underwrite.identity,
          },
          {
            name: "客户姓名",
            value: underwrite.idName,
          },
          {
            name: "证件号码",
            value: underwrite.idNo,
          },
          {
            name: "性别",
            value: underwrite.idSex,
          },
        ];
        return arr;
      }
    },
    resetEnv(record) {
      if (record) {
        let arr = [
          {
            name: "设备类型",
            value: "环保移动",
          },
          {
            name: "任务编号",
            value: record.taskNo,
          },
          {
            name: "受检单位",
            value: record.inspectName,
          },
          {
            name: "地址",
            value: record.sceneAddress,
          },
          {
            name: "负责人",
            value: record.sceneUsername,
          },
          {
            name: "监测类别",
            value: record.purpose,
          },
          {
            name: "测量日期",
            value: record.sampleDate,
          },

          {
            name: "生产工况",
            value: record.produceState,
          },
          {
            name: "处理工艺",
            value: record.processName,
          },
        ];
        return arr;
      } else {
        return [];
      }
    },
    resetDay(data) {
      if (data) {
        let arr = [];
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            let t = {
              time: key,
            };
            const element = data[key];
            Object.assign(t, element);
            // console.log(t);
            arr.push(t);
          }
        }
        return arr;
      }
    },
    resetMap(map) {
      if (map) {
        let arr = [];
        for (const key in map) {
          if (Object.hasOwnProperty.call(map, key)) {
            let t = {
              deviceName: key,
            };
            const element = map[key];
            Object.assign(t, element);
            // console.log(t);
            arr.push(t);
          }
        }
        return arr;
      }
    },
    resetScene(sceneData) {
      if (sceneData) {
        let obj = {};
        for (const key in sceneData) {
          if (Object.hasOwnProperty.call(sceneData, key)) {
            let o = {};
            const element = sceneData[key];
            console.log(key, element);
            if (key == "企业确认") {
              o = {
                entConfir: element,
              };
            }
            if (key == "企业签到") {
              o = {
                entCheck: element,
              };
            }
            if (key == "工作证(1)") {
              o = {
                workCard1: element,
              };
            }
            if (key == "工作证(0)") {
              o = {
                workCard0: element,
              };
            }
            if (key == "现场人员1") {
              o = {
                scenePer1: element,
              };
            }
            if (key == "现场人员2") {
              o = {
                scenePer2: element,
              };
            }

            if (key == "封箱照片(0)") {
              o = {
                closePho: element,
              };
            }

            console.log(o);
            Object.assign(obj, o);
          }
        }
        return obj;
      }
    },
    resetEnvinfo() {},
    envInfo() {
      let obj = {
        accordingTo: "厦环综[2023]8号",
        auditReason: null,
        auditorId: null,
        clModel: null,
        clSerial: null,
        correctorId: null,
        createTime: "2023-11-08 09:49:35",
        doModel: null,
        doSerial: null,
        inspectAddress: "集美区杏南路68号",
        inspectId: 10,
        inspectName: "路达(厦门)工业有限公司(杏南一厂)",
        inspectPhone: "18959275056",
        inspectSign:
          "http://119.45.13.61:8060/profile/upload/2023/11/08/1699416032872_20231108120034A210.png",
        isStandard: true,
        latitude: 24.555,
        longitude: 118.043,
        measurerSign:
          "http://119.45.13.61:8060/profile/upload/2023/11/08/1699416057029_20231108120058A212.png",
        phA1: null,
        phA2: null,
        phA3: null,
        phA4: null,
        phModel: "Multi3630",
        phSerial: "sz-12",
        phT1: null,
        phT2: null,
        phT3: null,
        phT4: null,
        processName: "生化法",
        produceState: "正常",
        purpose: "执法监测",
        recordId: 427,
        refuseVideo: null,
        remainSample: 0,
        remark: null,
        sampleDate: "2023-11-08",
        sampler: null,
        samplerId: null,
        samplerSign:
          "http://119.45.13.61:8060/profile/upload/2023/11/08/1699416050207_20231108120051A211.png",
        sceneAddress: null,
        sceneCard: [
          "http://119.45.13.61:8060/profile/upload/2023/11/08/IMG_20231108095219590_20231108095224A172.jpeg",
          "http://119.45.13.61:8060/profile/upload/2023/11/08/IMG_20231108095231945_20231108095236A173.jpeg",
        ],
        sceneChart: null,
        sceneIdentity: "350205198106040086",
        sceneImg:
          "http://119.45.13.61:8060/profile/upload/2023/11/08/IMG_20231108113144849_20231108113152A193.jpeg",
        sceneSign:
          "http://119.45.13.61:8060/profile/upload/2023/11/08/1699414377259_20231108113258A194.png",
        sceneTxt: "",
        sceneUsername: "刘微",
        signTxt: null,
        status: null,
        taskId: 120,
        taskNo: "202310230154",
        toolVideo: null,
        sampleVideo:
          "http://119.45.13.61:8060/profile/upload/2023/11/08/VID_20231108110844487_20231108110943A190.mp4",
        dealVideo:
          "http://119.45.13.61:8060/profile/upload/2023/11/08/VID_20231108111619832_20231108111757A192.mp4",
        sampleImg: [
          "http://119.45.13.61:8060/profile/upload/2023/11/08/IMG_20231108101016135_20231108101021A179.jpeg",
          "http://119.45.13.61:8060/profile/upload/2023/11/08/IMG_20231108101008616_20231108101013A178.jpeg",
        ],
        reportImg: [
          "http://119.45.13.61:8060/profile/upload/2023/11/13/Snipaste_2023-11-13_09-23-58_20231113092754A001.png",
          "http://119.45.13.61:8060/profile/upload/2023/11/13/Snipaste_2023-11-13_09-24-07_20231113092928A002.png",
        ],
        transportImg: [
          "http://119.45.13.61:8060/profile/upload/2023/11/08/IMG_20231108120718208_20231108120722A217.jpeg",
          "http://119.45.13.61:8060/profile/upload/2023/11/08/IMG_20231108120638666_20231108120643A216.jpeg",
          "http://119.45.13.61:8060/profile/upload/2023/11/08/IMG_20231108120604713_20231108120609A214.jpeg",
          "http://119.45.13.61:8060/profile/upload/2023/11/08/IMG_20231108120543330_20231108120549A213.jpeg",
          "http://119.45.13.61:8060/profile/upload/2023/11/08/IMG_20231108120620976_20231108120625A215.jpeg",
        ],
        transportVideo: null,
        updateTime: "2023-11-08 12:08:32",
        waterGo: "1",
        waterLive: true,
        waterProduct: true,
        waterRegular: "0",
        weather: "晴天",
      };
      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 80%;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .light {
    width: 410px;
    // height: 400px;
    // background-color: #fff;
    .logo {
      width: 100%;
      // height: 250px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: 50% 50%;
      }
    }
    .dots {
      width: 410px;
      height: 60px;
      margin-top: 10px;
      /*  display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap; */
      /*     .dot {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
         
      } */
      .dot_item {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 20px;
        .dot {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #fff;
          background-color: gray;
        }
        .dot1 {
          background-color: greenyellow;
        }
        .dot2 {
          background-color: #e94242;
        }
      }
    }
  }
  .flow {
    // width: 100%;
    // margin-top: 20px;
    display: flex;
    .upload {
      width: 200px;
      height: 140px;
      margin: 0 auto;
      border-radius: 10%;
    }
  }
  .enter_tips {
    font-size: 20px;
    color: #fff;
  }
  .arrow-enter-active,
  .arrow-leave-active {
    transition: all 4s ease;
  }
  .arrow-enter,
  .arrow-leave-to {
    opacity: 0;
    // width: 0;
  }
  .next {
    width: 340px;
    // opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /*   .next_width {
    width: 340px;
  } */

  .arrow {
    width: 140px;
    height: 140px;
    margin: 0 auto;
    border-radius: 10%;
    // background-color: rgba(8, 124, 219, 0.438);
    display: flex;
    justify-content: center;
    align-items: center;
    .right_arrow {
      width: 60px;
      height: 60px;
      // background-color: antiquewhite;
      background: url("../assets/images/arrow_right.png") no-repeat center 0px;
      background-position: center 0px;
      background-size: cover;
    }
  }
  /deep/ .border-box-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .up {
      display: block;
      position: relative;
      z-index: 2;
      width: 80%;
      height: 80%;
      border-radius: 10%;
      background-color: rgba(8, 124, 219, 0.438);
      .info {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #fff;
        z-index: 8;
        word-wrap: break-word;
        word-break: break-word;
        border: 1px solid #000;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .input {
        position: relative;
        z-index: 9;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .err_up {
      background-color: #e94242 !important;
    }
    .success_up {
      background-color: rgba(172, 255, 47, 0.678) !important;
    }
  }
}
</style>
