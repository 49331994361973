<template>
  <div>
    <v-chart :option="option_column" style="height: 400px"></v-chart>
  </div>
</template>

<script>
export default {
  data() {
    return {
      option_column: {
        /*    title: { text: "Column Chart" },
        tooltip: {},
        xAxis: {
          data: ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"],
        },
        yAxis: {},
        series: [
          {
            name: "销量",
            type: "bar",
            data: [5, 20, 36, 10, 10, 20],
          },
        ], */
        /*  title: {
          text: "评价",
          target: "blank",
          textAlgin: "left",
        },
        tooltip: {},
        legend: {
          data: ["name1"],
        },
        radar: {
          name: {
            textStyle: {
              color: "#fff", //字体颜色
              backgroundColor: "#bc4458", //背景色
              borderRadius: 3, //圆角
              padding: [3, 5], //padding
            },
          },
          center: ["50%", "50%"],
          radius: "60%",
          startAngle: 270,
          indicator: [
            {
              name: "完整性",
              max: 100,
            },
            {
              name: "准确性",
              max: 100,
            },
            {
              name: "及时性",
              max: 100,
            },
            {
              name: "一致性",
              max: 100,
            },
          ],
        },
        series: [
          {
            name: "测试标题名字",
            type: "radar",
            data: [
              {
                value: [95, 95, 90, 95, 100],
                name: "测试结果",
              },
            ],
          },
        ], */
      },
    };
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      this.option_column = {
        title: {
          text: "评价",
          target: "blank",
          textAlgin: "left",
        },
        tooltip: {}, //提示层
        legend: {
          data: ["name1"],
        },
        radar: {
          name: {
            textStyle: {
              color: "#fff", //字体颜色
              backgroundColor: "#bc4458", //背景色
              borderRadius: 3, //圆角
              padding: [3, 5], //padding
            },
          },
          center: ["50%", "50%"],
          radius: "60%",
          /*  axisLine: {
            lineStyle: {
              color: "red",
            },
          }, */
          startAngle: 270,
          indicator: [
            {
              name: "完整性",
              max: 100,
            },
            {
              name: "准确性",
              max: 100,
            },
            {
              name: "及时性",
              max: 100,
            },
            {
              name: "一致性",
              max: 100,
            },
            /* {
              name: "艺术与人文",
              max: 100,
            }, */
          ],
        },
        series: [
          {
            name: "评价结果名字",
            type: "radar",
            itemStyle: {
              color: "red",
            },
            areaStyle: {
              color: "blue",
              opacity: 0.2,
            },
            data: [
              {
                value: [100, 100, 100, 100],
                name: "评价结果",
              },
            ],
          },
        ],
      };
    },
  },
};
</script>
