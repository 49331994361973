import request from "./request";

// 校验获取文件数据
export const getFile = (data) => {
  return request({
    url: `/prod-api/v2/admin/data/evidence/cloud/check`,
    // url: `/ap/v2/admin/data/evidence/cloud/check`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data;charset=utf-8",
    },
    data: data,
  });
};
export const getIp = (data) => {
  return request({
    url: `/ap/api/wangming`,
    method: "post",
    data: data,
  });
};
