// 导入axios库，在组件中使用时不需要再进行导入
import axios from "axios";

// 调用 axios.create()函数，创建一个axios的实例对象，用request 接收

const request = axios.create({
  // 指定请求的根路径
  // baseURL: "",
  baseURL: "https://iot.kunming-biotech.com",
  timeout: 6000000,
});

export default request;
